@import 'assets/scss/variable.module.scss';

.container {
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
  @media (min-width: 1024px) {
    padding-top: 65px;
    padding-bottom: 180px;
  }
}

.topRightEllipse {
  position: absolute;
  width: 652px;
  height: 652px;
  top: -330px;
  right: -242px;
  @media (min-width: 1024px) {
    width: 2021px;
    height: 2021px;
    top: -800px;
    right: -1260px;
  }
  z-index: 0;
  opacity: 0.25;
}

.title {
  font-family: 'Muli-SemiBold';
  font-weight: 100;
  font-size: 24px;
  line-height: 30px;
  color: rgba(var(--text-gray-200-color-rgb), 0.85);
}

.slotBG {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  background: var(--time-slot-bg);
  padding: 0px 6px;
  z-index: 10;
}

.noPaddingBottom {
  padding-bottom: 0 !important;
}
