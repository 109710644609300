@import 'assets/scss/variable.module.scss';

.otherBG {
  width: 100%;
  display: flex;
  justify-content: center;
  background: var(--bg-1A1A1A-E8E7E5);
}

.liveAuctionBG {
  width: 100%;
  display: flex;
  justify-content: center;
  background: transparent;
}

.newReleaseSection {
  width: 100%;
  display: flex;
  justify-content: center;
  // background: var(--bg-1A1A1A-E8E7E5);
  background: radial-gradient(var(--bg-242424-F3F2F0), var(--bg-0A0A0A-E8E7E5));
}

.studioHeroSection {
  width: 100%;
  display: flex;
  justify-content: center;
  background: #1A1A1A;
}

.editionSection {
  width: 100%;
  display: flex;
  justify-content: center;
  // border-bottom: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  background: var(--bg-1A1A1A-E8E7E5);
}

.limitEditionSection {
  width: 100%;
  display: flex;
  justify-content: center;
  // border-bottom: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
}

.liveEditions {
  width: 100%;
  text-align: center;
  margin-top: 60px;
  font-family: "Muli-Bold";
  font-size: 40px;
  color: var(--text-gray-200-color);
  font-weight: 100;
}

.pushingBoundary {
  width: 100%;
  padding: 30px 0px;
  @media (min-width: 1024px) {
    padding: 50px 0px;
  }
  background: var(--bg-151515-1A1A1A);
  // border-top: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  // border-bottom: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  display: flex;
  justify-content: center;
}

.boundaryIcon {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #eff0f6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pushTitle {
  font-family: "Muli-SemiBold";
  font-size: 15px;
  line-height: 19px;
  font-weight: 100;
  letter-spacing: 0px;
  text-align: center;
  color: var(#eff0f6, 0.9);
  width: calc(100% + 32px);
  margin-left: -16px;
  @media (min-width: 1024px) {
    width: 100%;
    margin-left: 0px;
    font-family: "Muli-Bold";
    font-size: 20px;
    line-height: 25px;
    color: #eff0f6;
  }
}

.pushText {
  font-family: "Muli-Light";
  font-size: 14px;
  line-height: 18px;
  margin-top: 7px;
  text-align: center;
  @media (min-width: 1024px) {
    font-family: "Muli-Light";
    font-size: 16px;
    line-height: 20px;
    margin-top: 5px;
  }
  color: var(#eff0f6, 0.9);
}

.collectionSection {
  width: 100%;
  display: flex;
  justify-content: center;
  background: transparent;
  // background: var(--bg-141313-E8E7E5);
  // border-top: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
}

.shopMerchSection {
  width: 100%;
  border-top: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  @media (min-width: 1024px) {
    // border-bottom: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  }
  display: flex;
  justify-content: center;
}

.exploreBtn {
  width: 120px;
  height: 40px;
  border-radius: 20px;
  font-size: 16px;
  color: rgba(#1a1a1a, 1);
  @media (min-width: 1024px) {
    width: 140px;
    height: 50px;
    border-radius: 25px;
    font-size: 18px;
    color: rgba(#1a1a1a, 0.8);
  }
  background: #95dbd5;
  font-family: 'Muli-SemiBold';
  font-weight: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    color: #1a1a1a;
  }
  cursor: pointer;
}

.buyButton {
  width: 184px;
  height: 50px;
  border-radius: 25px;
  padding-left: 23px;
  padding-right: 8px;
  background: #FFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Muli-SemiBold';
  font-size: 18px;
  color: #171717;
  cursor: pointer;
}

.buttonIcon {
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background: #1A1A1A;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrowIcon {
  fill: #FFF !important;
}