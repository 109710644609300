@import 'assets/scss/variable.module.scss';

.container {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  width: 100%;
  background-color: var(--bg-new-color);
  justify-content: center;
  align-items: center;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
  &:focus {
    outline: none;
  }
}

.preLoaderView {
  position: absolute;
  inset: 0px;
  background: rgba(#121212, 0.8);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(12px);
  z-index: 10000;
}

.welcome {
  font-family: 'Muli-SemiBold';
  font-size: 30px;
  line-height: 38px;
  color: var(--text-gray-200-color);
}

.justDoArt {
  font-family: 'Muli-Regular';
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 4px;
  @media (min-width: 1024px) {
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 6px;
  }
  color: rgba(var(--text-gray-200-color-rgb), 0.9);
}
