@import 'assets/scss/variable.module.scss';

.container {
  max-width: 1440px;
  width: 100%;
  max-height: 575px;
  height: 100%;
  border-radius: 20px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  background-color: var(--profile-card-bg);
  border: solid 1px rgb(var(--text-gray-200-color-rgb), 0.1);
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-template-rows: 4fr 1fr;
  }
}

.topMask {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(var(--background-black-color), 90%),
    rgba(var(--background-black-color), 0%)
  );
}

.imageArea {
  background: rgba(var(--main-bg-color-rgb), 0.05);
  min-height: 575px;
  @media (max-width: 1600px) {
    min-height: 450px;
  }
  z-index: 100;
}

.imageContent {
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 45px;
  // box-shadow: 0px 0px 10px rgba(#202427, 0.1);
}

.nftImage {
  object-fit: contain;
  max-height: 360px;
  @media (min-width: 1600px) {
    max-height: 485px;
  }
}

.videoArea {
  width: 100%;
  height: 300px;
  @media (min-width: 1600px) {
    height: 418px;
  }
  overflow: hidden;
}

.infoBox {
  padding: 30px 40px 30px 30px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  z-index: 100;
  @media (min-width: 1024px) {
    padding: 30px;
  }
  @media (min-width: 1440px) {
    padding: 43px 35px 30px 35px;
  }
}

.cardArea {
  position: absolute;
  bottom: 8px;
  left: 12px;
  width: auto;
  border-radius: 10px;
  max-height: 114px;
  height: 74px;
  padding: 18px 30px 18px 14px;
  background: transparent; //(var(--collection-card-bg-rgb), 0.4);
  // -webkit-backdrop-filter: blur(20px);
  // backdrop-filter: blur(20px);
  @media (min-width: 1024px) {
    position: absolute;
    bottom: 40px;
    left: 35px;
    right: 40px;
    height: 100px;
    border-radius: 24px;
    padding: 15px 18px;
  }
  @media (min-width: 1536px) {
    position: absolute;
    bottom: 40px;
    left: 35px;
    right: 40px;
    height: 100%;
    border-radius: 30px;
    padding: 20px 35px;
  }
}

.avatarArea {
  width: fit-content;
  display: flex;
  gap: 21px;
  align-items: center;
  overflow: hidden;
}

.nftNames {
  p:first-child {
    font-family: 'Muli-SemiBold';
    font-size: 20px;
    @media (min-width: 1440px) {
      font-size: 22px;
      line-height: 30px;
    }
    color: var(--text-gray-200-color);
  }
  p:nth-child(2) {
    font-family: 'Muli-Light'; 
    font-size: 16px;
    margin-top: 2px;
    @media (min-width: 1440px) {
      margin-top: 3px;
      font-size: 18px;
      line-height: 23px;
    }
    color: rgba(var(--main-bg-color-rgb), 0.9);
  }
}

.buyButton {
  width: 184px;
  height: 50px;
  border-radius: 25px;
  padding-left: 23px;
  padding-right: 8px;
  background: var(--bg-FFF-202427);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Muli-SemiBold';
  font-size: 18px;
  color: var(--bg-171717-F3F2F0);
  cursor: pointer;
}

.buttonIcon {
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background: var(--bg-1A1A1A-F3F2F0);
  display: flex;
  justify-content: center;
  align-items: center;
}

.collectionName {
  font-family: 'Muli-Regular';
  font-size: 12px;
  line-height: 16px;
  color: var(--brand-03-color);
  @media (min-width: 1024px) {
    margin-top: 4px;
    font-family: 'Muli-Bold';
    font-size: 14px;
    line-height: 20px;
    color: var(--brand-03-color);
  }
  @media (min-width: 1536px) {
    margin-top: 4px;
    font-family: 'Muli-Bold';
    font-size: 18px;
    line-height: 24px;
    color: var(--brand-03-color);
  }
  letter-spacing: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.avatar {
  width: 45px;
  height: 45px;
  border-radius: 23px;
  @media (min-width: 1440px) {
    width: 66px;
    height: 66px;
    border-radius: 33px;
  }
}

.btnBox {
  display: flex;
  gap: 16px;
  background: var(--hero-avatar-bg-rgb);
}

.firstBtn {
  width: 120px;
  height: 40px;
  font-size: 14px;
  @media (min-width: 1280px) {
    width: 130px;
    height: 45px;
    font-size: 16px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Muli-SemiBold';
  border-radius: 12px;
  color: var(--pagination-down-icon);
  border: solid 1px rgba(var(--background-light-color-rgb), 0.25);
}

.secondBtn {
  width: 80px;
  height: 40px;
  @media (min-width: 1280px) {
    width: 100px;
    height: 45px;
    font-size: 16px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Muli-SemiBold';
  font-size: 14px;
  border-radius: 12px;
  background-color: var(--main-icon-color);
  color: var(--time-slog-bg);
}

.price {
  font-family: 'Muli-SemiBold';
  font-size: 18px;
  line-height: 23px;
  margin-top: -2px;
  color: var(--text-gray-200-color);
}

.priceLabel {
  font-family: 'Muli-Light';
  font-size: 16px;
  line-height: 20px;
  color: var(--artwork-table-tr);
}

.sold {
  font-family: 'Muli-SemiBold';
  font-size: 18px;
  line-height: 22px;
  color: var(--text-gray-200-color);
}

.priceBox {
  width: auto;
  display: flex;
  justify-content: center;
  gap: 6px;
  align-items: center;
}

.noSelect {
  user-select: none;
  // box-shadow: 0px 3px 6px rgba(#000, 0.16);
}

.endTimeContainer {
  margin-left: 4px;
  @media (min-width: 1024px) {
    margin-left: 2px;
  }
  @media (min-width: 1680px) {
    margin-left: 4px;
  }
}

.endTime {
  display: flex;
  gap: 1px;
  font-family: 'Muli-SemiBold';
  font-size: 18px;
  line-height: 22px;
  color: var(--text-gray-200-color);
}

.bidDesc {
  font-family: 'Muli-SemiBold';
  font-weight: 100;
  font-size: 18px;
  line-height: 22px;
  color: (--text-gray-200-color);
}

.skeletonLoader:empty {
  display: block;
  background-color: var(--main-bg-color) !important;
  border-color: var(--main-bg-color) !important;
  animation-name: skeletonAnimation;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  &.others {
    height: 453px;
  }

  &.prod-name {
    height: 20px;
  }

  &.prod-description {
    width: 80%;
    height: calc(100% - 30px);
  }
}

@keyframes skeletonAnimation {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.6;
  }
}

.sol_icon_color {
  * {
    fill: var(--main-icon-color);
  }
}
