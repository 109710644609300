@import 'assets/scss/variable.module.scss';

.container {
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
  @media (min-width: 1024px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  width: 100%;
}

.title {
  font-family: 'Muli-SemiBold';
  font-weight: 100;
  font-size: 24px;
  line-height: 30px;
  color: rgba(var(--text-gray-200-color-rgb), 0.85);
}

.seeMore {
  font-family: 'Muli-Regular';
  font-size: 14px;
  color: var(--text-gray-200-color);
}

.smallTitle {
  font-family: 'Muli-SemiBold';
  font-size: 18px;
  line-height: 20px;
  margin-left: 15px;
  @media (min-width: 1024px) {
    font-family: 'Muli-Bold';
    font-size: 20px;
    line-height: 24px;
  }
  color: var(--text-gray-200-color);
}

.seeMore {
  font-family: 'Muli-SemiBold';
  font-size: 14px;
  color: var(--text-gray-200-color);
}

.slotBG {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  background: var(--time-slot-bg);
  padding: 0px 6px;
  z-index: 10;
}
