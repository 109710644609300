@import 'assets/scss/variable.module.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  background: transparent;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  @media (min-width: 640px) {
    font-family: 'Muli-SemiBold';
    padding-left: 12px;
    padding-right: 12px;
    font-size: 16px;
  }
  @media (min-width: 1536px) {
    padding-left: 16px;
    padding-right: 16px;
    font-size: 16px;
  }
  border-radius: 12px;
  font-family: 'Muli-SemiBold';
  line-height: 30px;
  letter-spacing: 0;
  color: rgba(var(--main-bg-color-rgb), 0.75);

  &:hover {
    @media (min-width: 1024px) {
      text-decoration: underline;
    }
  }
  transition: opacity 0.2s;
  transition-timing-function: ease-in-out;
}
