@import 'assets/scss/variable.module.scss';

.container {
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
  @media (min-width: 1024px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.bottomLeftEllipse {
  position: absolute;
  width: 652px;
  height: 652px;
  top: -280px;
  left: -388px;
  @media (min-width: 1024px) {
    width: 2021px;
    height: 2021px;
    top: -500px;
    right: -580px;
  }
  z-index: 0;
  opacity: 0.25;
}

.title {
  font-family: 'Muli-SemiBold';
  font-weight: 100;
  font-size: 24px;
  line-height: 30px;
  color: rgba(var(--text-gray-200-color-rgb), 0.85);
}

.seeMore {
  font-family: 'Muli-SemiBold';
  font-size: 14px;
  color: var(--text-gray-200-color);
}
