@import 'assets/scss/variable.module.scss';

.layout {
  width: 100%;
  height: 100%;
}

.container {
  width: 100%;
  height: auto;
  background: var(--background-main-color);
  border: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  border-radius: 10px;
  position: relative;
  overflow-y: hidden;
  margin-top: 2px;
  margin-bottom: 0px;
  &:hover {
    @media (min-width: 1024px) {
      margin-top: 0px;
      margin-bottom: 2px;
    }
  }
}

.imageArea {
  position: relative;
  width: 100%;
  padding-top: 27.64%; /* 359:393 Aspect Ratio */
  @media (min-width: 640px) {
    padding-top: 34.43%; /* 359:393 Aspect Ratio */
  }
}

.imageContent {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.containerHover {
  box-shadow: 0px 0px 20px rgba(var(--main-icon-color-rgb), 0.4);
}

.name {
  font-family: 'Muli-SemiBold';
  font-size: 18px;
  line-height: 22px;
  font-weight: 100;
  @media (min-width: 640px) {
    font-family: 'Muli-ExtraBold';
    font-size: 20px;
  }
  color: var(--text-gray-200-color);
}

.followers {
  font-family: 'Muli-SemiBold';
  font-size: 14px;
  line-height: 18px;
  font-weight: 100;
  @media (min-width: 640px) {
    font-family: 'Muli-SemiBold';
    font-size: 16px;
  }
  color: var(--text-gray-200-color);
}

.followLabel {
  font-family: 'Muli-Light';
  font-size: 14px;
  line-height: 18px;
  font-weight: 100;
  @media (min-width: 640px) {
    font-family: 'Muli-Light';
    font-size: 16px;
  }
  color: var(--text-gray-200-color);
}

.bio {
  font-family: 'Muli-Regular';
  font-size: 14px;
  color: rgba(#eff0f6, 0.8);
  margin-top: 27px;
  max-height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  padding: 0px 15px;
  
  @media (min-width: 640px) {
    margin-top: 10px;
    font-size: 16px;
    max-height: 50px;
    text-align: start;
    padding: 0px;
  }
}

.artistProfile {
  position: relative;
  border: 3px solid var(--background-main-color);
  background: var(--background-main-color);
  border-radius: 48px;
  min-width: 95px;
  min-height: 95px;
  width: 95px;
  height: 95px;
  margin-top: -47px;
  margin-left: 13px;
  @media (min-width: 640px) {
    border-radius: 60px;
    min-width: 120px;
    min-height: 120px;
    width: 120px;
    height: 120px;
    margin-top: -60px;
    margin-left: 40px;
  }
  @media (min-width: 1024px) {
    border-radius: 60px;
    min-width: 120px;
    min-height: 120px;
    width: 120px;
    height: 120px;
    margin-top: -60px;
    margin-left: 30px;
  }
  @media (min-width: 1200px) {
    border-radius: 60px;
    min-width: 120px;
    min-height: 120px;
    width: 120px;
    height: 120px;
    margin-top: -60px;
    margin-left: 30px;
  }
  @media (min-width: 1536px) {
    border-radius: 75px;
    min-width: 150px;
    min-height: 150px;
    width: 150px;
    height: 150px;
    margin-top: -75px;
    margin-left: 55px;
  }
  overflow: hidden;
}

.mobileContainer {
  width: 100%;
  height: 293px
}

.overlay {
  position: absolute;
  inset: 0;
  background: rgba(#000, 0.8);
}

.socialIcon {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  border: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  background: var(--activity-bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    fill: var(--bg-eff0f6-707070);
  }
  &:hover {
    border: 1px solid var(--main-icon-color);
    svg {
      fill: var(--main-icon-color);
    }
  }
}